import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/Layout/index'
import Index from "../../components/MetaTags/index";
import styles from "../about.module.css";
import coverImage from "./cover-encyclopedia-gallactica.jpg";

const DaoPage = (props) => (
  <Layout>
    <Index
      title={`About Graphopedia`}
      description="Graphopedia contains the biggest collection of data visualizations and inforaphics. Read more about how it works."
    />
    <div className={styles.cover}><img src={coverImage} /></div>
    <h1>Graphopedia DAO</h1>
    <p>
        <q>
            The Tao that can be told is not the eternal Tao.<br />
            The name that can be named is not the eternal name.<br />
            The nameless is the beginning of heaven and earth.<br />
            The named is the mother of ten thousand things.<br />
            Ever desireless, one can see the mystery.<br />
            Ever desiring, one can see the manifestations.<br />
            These two spring from the same source but differ in name;<br />
            this appears as darkness.<br />
            Darkness within darkness     
        </q> 
    </p>
    <h2>What's DAO?</h2>
    <p>
        A decentralized autonomous organization (DAO), sometimes called a decentralized autonomous corporation (DAC), 
        is an organization constructed by rules encoded as a computer program that is often transparent, 
        controlled by the organization's members and not influenced by a central government.
    </p>
    <p>
       Read <a href="https://ethereum.org/en/dao/"><em>What's DAO</em></a> at etherium foundation
    </p>
    <h2>How to fund our DAO?</h2>
    <p>
       Just send us some crypto and you are all set.
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default DaoPage
